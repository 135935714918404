/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import CarteRestaurantView from "../views/CarteRestaurantView.vue";
import ContactView from "../views/ContactView.vue";
import ConditionsCommercialesView from "../views/ConditionsCommercialesView.vue";
import CarteBuffetView from "../views/CarteBuffetView.vue";
import CarteCocktailsView from "../views/CarteCocktailsView.vue";
import CarteBoissonsView from "../views/CarteBoissonsView.vue";
import CartePlateauxRepasView from "../views/CartePlateauxRepasView.vue";
import ContactEventView from "../views/ContactEventView.vue";
import NotFoundView from "../views/NotFoundView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },

    {
        path: "/restaurant",
        name: "carte",
        component: CarteRestaurantView,
    },
    {
        path: "/nos-buffets",
        name: "buffets",
        component: CarteBuffetView,
    },
    {
        path: "/nos-cocktails",
        name: "cocktails",
        component: CarteCocktailsView,
    },
    {
        path: "/nos-plateaux-repas",
        name: "plateauxrepas",
        component: CartePlateauxRepasView,
    },
    {
        path: "/nos-boissons",
        name: "boissons",
        component: CarteBoissonsView,
    },
    {
        path: "/conditions-commerciales",
        name: "conditions_commerciales",
        component: ConditionsCommercialesView,
    },
    {
        path: "/contact",
        name: "contact",
        component: ContactView,
    },
    {
        path: "/seminaires-evenements",
        name: "contactevent",
        component: ContactEventView,
    },
    {
        path: "/done",
        name: "done",
        redirect: (to) => {
            localStorage.fromty = true;
            return { name: "home" };
        },
    },
    {
        path: "/404-not-found",
        name: "404",
        component: NotFoundView,
    },
    {
        path: "*",
        redirect: { name: "404" },
    },
];

const router = new VueRouter({
    mode: "history",
    base: __dirname,
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);

    next();
});

export default router;
