<template>
    <div class="carte-traiteur container-lg my-3 my-sm-4 my-md-5">
        <h1 class="title">Carte des Boissons</h1>

        <div class="my-5 text-center border-bottom border-primary"></div>

        <div class="row g-3">
            <div class="col-12 col-md-6" v-for="item in items" v-bind:key="item">
                <img :src="getMenuItemUrl(item)" class="mw-100 rounded shadow" alt="Carte boissons" />
            </div>
        </div>

        <div class="d-flex flex-column align-items-center mt-3 mt-sm-4 mt-md-5">
            <button type="button" @click="openMenuPdf" class="btn btn-primary btn-lg text-white">
                <i class="fa-solid fa-file-pdf me-3 fa-lg"></i>
                <span>Voir la carte complète</span>
            </button>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { boissons, getMenuItemUrl } from "@/assets/menus";

export default {
    data() {
        return {
            items: boissons,
        };
    },
    methods: {
        openMenuPdf() {
            window.open("/resources/La Table du 9 Bis - Menu.pdf", "_blank");
        },
        getMenuItemUrl,
    },
};
</script>
