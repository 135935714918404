const restaurant = [1, 4, 5, 10, 11, 12, 13, 14];
const buffet = [15, 16, 17, 18];
const cocktail = [22, 19, 20, 21, 23];
const plateaux = [6, 7, 8, 9];
const boissons = [1, 24, 25, 26];
const cgv = [1, 2, 27, 28, 29, 30];

const getMenuItemUrl = (numero) => require(`@/assets/img/menu/2024-08/${numero}.svg`);

export {
    restaurant,
    buffet,
    cocktail,
    plateaux,
    boissons,
    cgv,
    getMenuItemUrl
}