// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDUU-vSsAoBvDZvMRMSW0iDXigVWYPPJ-k",
    authDomain: "mod1-consulting.firebaseapp.com",
    projectId: "mod1-consulting",
    appId: "1:667584104376:web:9b8ed237197241d2dc314d",
    measurementId: "G-WSEC9ZPMHN"
};

// // Initialize Firebase
const app = initializeApp(firebaseConfig);

const functions = getFunctions(app);
if (process.env.NODE_ENV == "-development") {
    console.log("Functions : DEBUG_MODE");
    connectFunctionsEmulator(functions, "localhost", 5001);
}

export { functions };
