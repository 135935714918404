<template>
    <div class="home content d-flex flex-column">
        <div
            v-if="isFromTY"
            class="alert alert-success bg-success container-lg text-white d-flex align-items-center rounded-1 mt-3"
            role="alert"
        >
            <i class="fa-solid fa-check me-3"></i>
            <div>
                Merci de nous avoir contacté, votre demande sera traitée dans les plus brefs délais ! Vous recevrez
                également un email de confirmation.
            </div>
        </div>

        <div class="home--header w-100 d-flex align-items-center justify-content-center">
            <span class="home--copyright">@le9bis</span>
            <img
                v-once
                src="../assets/img/home.jpg"
                fetchpriority="high"
                class="home--header--bg"
                alt="Batiment du 9 bis"
            />
            <span class="home--header--title">
                <img v-once src="../assets/img/logo_new.png" alt="Logo du restaurant" />
            </span>
        </div>
        <!-- d-grid gap-3 -->
        <div class="container-lg">
            <div
                class="row gx-0 gy-4 gy-md-0 bg-light px-2 px-sm-3 px-lg-4 py-2 py-sm-3 py-lg-4 my-5 justify-content-center"
            >
                <div class="col-6 col-md order-2 order-md-3 d-none d-sm-flex flex-column align-items-center">
                    <span class="fa-stack mb-2">
                        <i class="fa-solid fa-clock fa-stack-2x text-primary"></i>
                    </span>
                    <span class="text-primary-xlight mb-2 d-none d-sm-block">Horaires :</span>
                    <span class="text-primary d-flex flex-column align-items-center">
                        <span>Du lundi au vendredi <small>*</small></span>
                        <span><span class="text-primary-xlight">Pause :</span> à partir de 7h</span>
                        <span><span class="text-primary-xlight">Repas :</span> 12h-14h</span>
                    </span>
                </div>

                <div class="col-6 col-md order-3 order-md-2 d-flex align-items-center flex-column">
                    <span class="fa-stack mb-2">
                        <i class="fa-solid fa-circle fa-stack-2x text-primary"></i>
                        <i class="fa-solid fa-calendar-check fa-stack-1x fa-inverse text-white"></i>
                    </span>
                    <span class="text-primary-xlight d-sm-block">Réservations :</span>
                    <a href="tel:+33661363919" aria-label="06 61 36 39 19" class="btn btn-link d-flex-center">
                        06 61 36 39 19
                    </a>
                    <router-link
                        to="/contact"
                        aria-label="Contact"
                        class="btn btn-sm btn-primary text-white mx-3 my-2 d-none d-md-block"
                    >
                        Contact
                    </router-link>
                </div>

                <div class="col-6 col-md order-1 order-md-1 d-flex flex-column align-items-center">
                    <span class="fa-stack mb-2">
                        <i class="fa-solid fa-circle fa-stack-2x text-primary"></i>
                        <i class="fa-solid fa-location-dot fa-stack-1x fa-inverse text-white"></i>
                    </span>
                    <span class="text-primary-xlight mb-2 d-none d-sm-block">Adresse :</span>
                    <a
                        href="https://goo.gl/maps/nv36qZfNmfxyQm4cA"
                        aria-label="Adresse de la table du 9 bis"
                        target="_blank"
                        class="text-primary d-flex flex-column align-items-center"
                    >
                        <span>La Table du 9 Bis</span>
                        <span class="text-center">9 Bis boulevard Voltaire</span>
                        <span>Dijon, France</span>
                    </a>
                </div>
            </div>

            <div class="home--menu row g-3">
                <div class="col-12 col-lg-6">
                    <img
                        v-once
                        src="../assets/img/menu/2024-08/3.svg"
                        class="mw-100 rounded shadow"
                        alt="Nos pauses quotidiennes"
                    />
                </div>
                <div class="col-12 col-lg-6">
                    <img
                        v-once
                        src="../assets/img/menu/2024-08/4.svg"
                        class="mw-100 rounded shadow"
                        alt="Nos formules du jour"
                    />
                </div>
            </div>

            <div class="home--presentation my-5 px-3 px-lg-0 text-center bg-white rounded px-4 py-5">
                <p>
                    Bienvenue à <span class="text-primary">La Table du 9 Bis</span>, votre restaurateur et traiteur de
                    confiance à Dijon !
                </p>

                <p>
                    Au sein de la
                    <a href="https://maisondiocesaine-dijon.cef.fr/" target="_blank" aria-label="Maison diocesaine">
                        <u>Maison Diocésaine</u>
                        <i class="fa-solid fa-arrow-up-right-from-square ms-1"></i>
                    </a>
                    <span>, </span>
                    <span class="text-primary">La Table du 9 Bis</span> vous propose son offre de restauration
                    quotidienne à 17 € comprenant <span class="text-primary">entrée, plat et dessert</span>, nous
                    disposons d'une capacité d'accueil de <span class="text-primary">140 personnes</span>.
                </p>

                <p>
                    Nous sommes fiers de proposer une cuisine traditionnelle dijonnaise mettant en valeur les produits
                    locaux de la région.
                </p>

                <p>
                    Notre équipe de chefs expérimentés met tout son savoir-faire pour élaborer des menus de groupe ou
                    buffets froids personnalisés selon vos goûts et vos envies. Nous proposons également un service de
                    restauration pour les entreprises, pour vos déjeuners professionnels, cocktails, réunions et autres
                    événements d'entreprise.
                </p>

                <p>
                    Que vous cherchiez un <span class="text-primary">traiteur</span> pour votre mariage ou une
                    <span class="text-primary">restauration événementielle</span>, nous vous accompagnons pour
                    <span class="text-primary">tous vos évènements</span> à Dijon en vous proposant des prestations sur
                    mesure « clés en main » <span class="text-primary">salle et traiteur</span> pour organiser
                    <span class="text-primary">fêtes</span>, <span class="text-primary">déjeuners</span>,
                    <span class="text-primary">dîners familial</span>,
                    <span class="text-primary">vins d'honneur</span> ou encore
                    <span class="text-primary">buffets froids</span>.
                </p>

                <p>
                    Deux salles de restaurant, un parc de deux hectares, sont à votre disposition et toute une équipe à
                    votre écoute pour définir avec vous vos besoins.
                </p>

                <p>
                    <span class="text-primary">La Table du 9 Bis</span>, c'est aussi, en collaboration avec
                    <!-- <a href="http://www.le9bis.fr/" target="_blank" aria-label="Le Neuf Bis">
                        <u>Le Neuf Bis</u>
                        <i class="fa-solid fa-arrow-up-right-from-square ms-1"></i>
                    </a> -->
                    <u>Le Neuf Bis</u>
                    , la possibilité de vous proposer des <span class="text-primary">salles de réunions</span>, des
                    <span class="text-primary">solutions d'hébergement</span>, ainsi qu'une offre de
                    <span class="text-primary">prestations d'accueil</span>
                    (prestations de pause et café d'accueil).
                    <br />Contact : <a href="mailto:accueil.md@le9bis.fr">accueil.md@le9bis.fr</a>
                </p>

                <p>
                    Suivez nous sur
                    <a
                        href="https://www.facebook.com/latabledu9bis/"
                        aria-label="page facebook de la Table du 9 Bis"
                        target="_blank"
                    >
                        <u>notre page Facebook</u>
                        <i class="fa-solid fa-arrow-up-right-from-square ms-1"></i>
                    </a>
                    pour être au courant de notre actualité !
                </p>
            </div>

            <div class="home--salles my-3 my-sm-4 my-5">
                <h1 class="title border-top border-bottom border-primary py-5 mb-4">Les salles</h1>

                <div class="row justify-content-around">
                    <div class="col-md-6 mt-4">
                        <div class="d-flex flex-column border border-primary-xlight rounded border-2 p-3 bg-white">
                            <h2 class="fs-3 text-center av85 text-primary my-3">Le Restaurant</h2>

                            <div class="position-relative">
                                <img
                                    v-once
                                    src="../assets/img/restaurant.png"
                                    class="mw-100 my-4 border shadow rounded"
                                    alt="La salle Le Neuf"
                                />
                                <span class="home--copyright my-4">@le9bis</span>
                            </div>

                            <div class="my-2">
                                <span class="text-primary fw-bold me-3">Superficie :</span>
                                <span class="fw-bold">153.33 m²</span>
                            </div>

                            <div class="my-2">
                                <span class="text-primary fw-bold me-3">
                                    Configurations possibles et capacité d'accueil :
                                </span>
                                <div class="d-flex flex-column">
                                    <div class="d-flex my-1 align-items-center">
                                        <i class="fa-solid fa-utensils me-2 text-primary-light" style="width: 20px" />
                                        <span class="fw-bold me-2">Banquet :</span>
                                        <span>125 places assises</span>
                                    </div>
                                    <div class="d-flex my-1 align-items-center">
                                        <i
                                            class="fa-solid fa-martini-glass-citrus me-2 text-primary-light"
                                            style="width: 20px"
                                        />
                                        <span class="fw-bold me-2">Buffet/Cocktail :</span>
                                        <i class="fa-solid fa-plus-minus me-1 text-primary-light"></i>
                                        180 personnes
                                    </div>
                                </div>
                            </div>

                            <div class="my-2">
                                <span class="text-primary fw-bold me-3">Equipements :</span>
                                <div class="d-flex flex-column">
                                    <div class="d-flex my-1 align-items-baseline">
                                        <i class="fa-solid fa-wifi me-2 text-primary-light" style="width: 20px" />
                                        <span>Internet haut débit et wifi</span>
                                    </div>
                                    <div class="d-flex my-1 align-items-baseline">
                                        <i class="fa-solid fa-tv me-2 text-primary-light" style="width: 20px" />
                                        <span>
                                            Possibilité de vidéoprojecteur et écran de projection portable avec
                                            déroulement manuel
                                        </span>
                                    </div>
                                    <div class="d-flex my-1 align-items-baseline">
                                        <i class="fa-solid fa-chair me-2 text-primary-light" style="width: 20px" />
                                        <span>Chaises et tables de restaurant</span>
                                    </div>
                                    <div class="d-flex my-1 align-items-baseline">
                                        <i class="fa-solid fa-music me-2 text-primary-light" style="width: 20px" />
                                        <span>Sonorisation + micro sans fil</span>
                                    </div>
                                </div>
                            </div>

                            <div class="my-2">
                                <div>
                                    <i class="fa-solid fa-wheelchair me-2 text-primary"></i>
                                    <span class="fw-bold">Accessible aux personnes à mobilité réduite</span>
                                </div>
                                <div>Au rdc du bâtiment avec accès direct sur le parc et le parking</div>
                            </div>

                            <div class="my-2">
                                <i class="fa-solid fa-euro me-2 text-primary"></i>
                                <span class="fw-bold">Tarif sur demande auprès du service des réservations</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mt-4">
                        <div class="d-flex flex-column border border-primary-xlight rounded border-2 p-3 bg-white">
                            <h2 class="fs-3 text-center av85 text-primary my-3">Le Neuf</h2>

                            <div class="position-relative">
                                <img
                                    v-once
                                    src="../assets/img/neuf.jpg"
                                    class="mw-100 my-4 border shadow rounded"
                                    alt="La salle Le Neuf"
                                />
                                <span class="home--copyright my-4">@le9bis</span>
                            </div>

                            <div class="my-2">
                                <span class="text-primary fw-bold me-3">Superficie :</span>
                                <span class="fw-bold">71.82 m²</span>
                            </div>

                            <div class="my-2">
                                <span class="text-primary fw-bold me-3">
                                    Configurations possibles et capacité d'accueil :
                                </span>
                                <div class="d-flex flex-column">
                                    <div class="d-flex my-1 align-items-center">
                                        <i class="fa-solid fa-utensils me-2 text-primary-light" style="width: 20px" />
                                        <span class="fw-bold me-2">Banquet :</span>
                                        <span>38 places assises</span>
                                    </div>
                                    <div class="d-flex my-1 align-items-center">
                                        <i
                                            class="fa-solid fa-martini-glass-citrus me-2 text-primary-light"
                                            style="width: 20px"
                                        />
                                        <span class="fw-bold me-2">Buffet/Cocktail :</span>
                                        <i class="fa-solid fa-plus-minus me-1 text-primary-light"></i>
                                        80 personnes
                                    </div>
                                </div>
                            </div>

                            <div class="my-2">
                                <span class="text-primary fw-bold me-3">Equipements :</span>
                                <div class="d-flex flex-column">
                                    <div class="d-flex my-1 align-items-baseline">
                                        <i class="fa-solid fa-wifi me-2 text-primary-light" style="width: 20px" />
                                        <span>Internet haut débit et wifi</span>
                                    </div>
                                    <div class="d-flex my-1 align-items-baseline">
                                        <i class="fa-solid fa-tv me-2 text-primary-light" style="width: 20px" />
                                        <span>
                                            Possibilité de vidéoprojecteur et écran de projection portable avec
                                            déroulement manuel
                                        </span>
                                    </div>
                                    <div class="d-flex my-1 align-items-baseline">
                                        <i class="fa-solid fa-chair me-2 text-primary-light" style="width: 20px" />
                                        <span>Chaises et tables de restaurant</span>
                                    </div>
                                    <div class="d-flex my-1 align-items-baseline">
                                        <i class="fa-solid fa-music me-2 text-primary-light" style="width: 20px" />
                                        <span>Possibilité sonorisation portable</span>
                                    </div>
                                </div>
                            </div>

                            <div class="my-2">
                                <div>
                                    <i class="fa-solid fa-wheelchair me-2 text-primary"></i>
                                    <span class="fw-bold">Accessible aux personnes à mobilité réduite</span>
                                </div>
                                <div>Au rdc du bâtiment avec accès direct sur le parc et le parking</div>
                            </div>

                            <div class="my-2">
                                <i class="fa-solid fa-euro me-2 text-primary"></i>
                                <span class="fw-bold">Tarif sur demande auprès du service des réservations</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 my-4 av85 text-white text-center bg-primary py-3 rounded">
                        <i class="fa-solid fa-circle-info me-3 fa-xl" />
                        <span>Ces deux salles sont communicantes pour des besoins ponctuels</span>
                    </div>
                    <div class="small mt-3 text-black-50">
                        *sauf les jours fériés. Les samedi, dimanche et jours fériés sur réservation uniquement, pour un
                        minimum de 25 personnes.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: "HomeView",
    data() {
        let _isFromTY = localStorage.fromty && JSON.parse(localStorage.fromty);
        localStorage.fromty = false;

        return {
            isFromTY: _isFromTY,
        };
    },
    methods: {
        openMenuPdf() {
            window.open("/resources/La Table du 9 Bis - Menu.pdf", "_blank");
        },
    },
};
</script>
