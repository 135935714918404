<template>
    <div class="carte-traiteur container-lg my-3 my-sm-4 my-md-5">
        <h1 class="title">Menu Restaurant</h1>

        <div class="my-5 text-center border-bottom border-top border-primary py-3">
            <p>
                Notre restaurant est ouvert du lundi au vrendredi pour le déjeuner et offre une expérience culinaire de
                qualité à des prix abordables, le tout idéalement situé à Dijon.
            </p>
            <p>
                Notre menu du déjeuner est conçu pour satisfaire les goûts les plus variés, avec une sélection de plats
                traditionnels français et d'ailleur.
            </p>
            <p>
                Nous utilisons des ingrédients de qualité pour offrir une expérience culinaire authentique et
                savoureuse, avec des plats préparés à la minute pour garantir la fraîcheur et la qualité.
            </p>
            <p>
                Notre équipe de service accueillante et professionnelle se fera un plaisir de vous accueillir et de vous
                offrir une expérience culinaire de qualité.
            </p>
            <p class="mb-0">
                Réservez votre table dès maintenant et découvrez pourquoi notre restaurant est l'endroit idéal pour
                savourer un déjeuner à Dijon.
            </p>
        </div>

        <div class="row g-3">
            <div class="col-12 col-md-6" v-for="item in items" v-bind:key="item">
                <img :src="getMenuItemUrl(item)" class="mw-100 rounded shadow" alt="Menu restaurant" />
            </div>
        </div>

        <div class="d-flex flex-column align-items-center mt-3 mt-sm-4 mt-md-5">
            <button type="button" @click="openMenuPdf" class="btn btn-primary btn-lg text-white">
                <i class="fa-solid fa-file-pdf me-3 fa-lg"></i>
                <span>Voir la carte complète</span>
            </button>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { restaurant, getMenuItemUrl } from "@/assets/menus";

export default {
    data() {
        return {
            items: restaurant,
        };
    },
    methods: {
        openMenuPdf() {
            window.open("/resources/La Table du 9 Bis - Menu.pdf", "_blank");
        },
        getMenuItemUrl
    },
};
</script>
