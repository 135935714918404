<template>
    <div class="contact container-xl mx-auto d-flex flex-column my-4 flex-fill body-container">
        <div v-if="isLoading" class="loader d-flex-center">
            <div class="d-flex-center flex-column bg-primary bg-opacity-75 rounded p-3 pt-5">
                <i class="fa-solid fa-utensils text-white fa-bounce fa-2xl"></i>
                <strong class="text-white mt-4--5 fs-4">Envoi de la demande</strong>
            </div>
        </div>

        <div class="container-md shadow my-0 my-md-5 px-0 bg-white">
            <div class="row gx-0 gy-4 gy-md-0 bg-primary px-2 px-sm-3 px-lg-4 py-4 shadow-sm">
                <div class="col-6 col-md order-2 order-md-4 d-flex flex-column align-items-center">
                    <span class="fa-stack mb-2">
                        <i class="fa-solid fa-circle fa-stack-2x text-white"></i>
                        <i class="fa-solid fa-location-dot fa-stack-1x fa-inverse text-primary"></i>
                    </span>
                    <span class="text-primary-xlight mb-2 d-none d-sm-block">Adresse :</span>
                    <a
                        href="https://goo.gl/maps/CdX31Rrz1y87rtg96"
                        target="_blank"
                        class="text-white d-flex flex-column align-items-center"
                    >
                        <span>La Table du 9 Bis</span>
                        <span class="text-center">9 Bis boulevard Voltaire</span>
                        <span>Dijon, France</span>
                    </a>
                </div>

                <div class="col-6 col-md order-3 order-md-2 d-flex flex-column align-items-center">
                    <span class="fa-stack mb-2">
                        <i class="fa-solid fa-circle fa-stack-2x text-white"></i>
                        <i class="fa-solid fa-phone fa-stack-1x fa-inverse text-primary"></i>
                    </span>
                    <span class="text-primary-xlight mb-2 d-none d-sm-block">Téléphone :</span>
                    <a href="tel:+33661363919" class="text-white">06 61 36 39 19</a>
                </div>

                <div class="col-6 col-md order-4 order-md-3 d-flex flex-column align-items-center">
                    <span class="fa-stack mb-2">
                        <i class="fa-solid fa-circle fa-stack-2x text-white"></i>
                        <i class="fa-solid fa-envelope fa-stack-1x fa-inverse text-primary"></i>
                    </span>
                    <span class="text-primary-xlight mb-2 d-none d-sm-block">Email :</span>
                    <a href="mailto:societejmg@outlook.fr" class="text-white">societejmg@outlook.fr</a>
                </div>

                <div class="col-6 col-md order-1 order-md-1 d-flex flex-column align-items-center">
                    <span class="fa-stack mb-2">
                        <i class="fa-solid fa-clock fa-stack-2x text-white"></i>
                    </span>
                    <span class="text-primary-xlight mb-2 d-none d-sm-block">Horaires :</span>
                    <span class="text-white d-flex flex-column align-items-center">
                        <span>Du lundi au vendredi</span>
                        <span><span class="text-primary-xlight">Pause :</span> à partir de 7h</span>
                        <span><span class="text-primary-xlight">Repas :</span> 12h-14h</span>
                    </span>
                </div>
            </div>

            <div class="row gx-0">
                <div class="col-12 col-lg-7 d-flex flex-column align-items-center px-2 py-4 px-sm-5 py-sm-5">
                    <h2 class="title text-uppercase mb-4--5">Nous contacter</h2>

                    <form method="POST" class="needs-validation" novalidate>
                        <div class="row g-3">
                            <div class="col-12">
                                <div class="form-floating">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="name"
                                        name="name"
                                        v-model="form_data.name"
                                        placeholder="Alain Dupond"
                                        required
                                    />
                                    <label for="name">Nom / Entreprise*</label>
                                    <div class="invalid-feedback">Veuillez renseigner votre nom</div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-floating">
                                    <input
                                        type="email"
                                        class="form-control"
                                        id="email"
                                        name="email"
                                        v-model="form_data.email"
                                        placeholder="nom@exemple.fr"
                                        required
                                    />
                                    <label for="email">Adresse email*</label>
                                    <div class="invalid-feedback">Adresse email invalide</div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-floating">
                                    <input
                                        type="tel"
                                        class="form-control"
                                        id="phone"
                                        name="phone"
                                        v-model="form_data.phone"
                                        placeholder="0605040302"
                                    />
                                    <label for="phone">Téléphone</label>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-floating">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="object"
                                        name="object"
                                        v-model="form_data.object"
                                        placeholder="ex: Privatisation restaurant"
                                        required
                                    />
                                    <label for="object">Objet*</label>
                                    <div class="invalid-feedback">Veuillez renseigner l'objet de votre demande</div>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-floating">
                                    <textarea
                                        class="form-control"
                                        placeholder="Laissez votre message ici"
                                        id="message"
                                        name="message"
                                        v-model="form_data.message"
                                        style="height: 150px"
                                        required
                                    ></textarea>
                                    <label for="message">Message*</label>
                                    <div class="invalid-feedback">Veuillez saisir un message pour votre demande</div>
                                </div>
                            </div>
                        </div>

                        <button @click="sendContact" class="w-100 btn btn-primary btn-lg mt-3" type="button">
                            Envoyer
                        </button>
                    </form>
                    <div class="small mt-3 text-primary-dark">* champs obligatoires</div>
                </div>
                <div class="col-12 col-lg-5">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10818.938180289811!2d5.052162!3d47.3195242!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f29d5f1e0d0ebf%3A0x5875b33b8e239cae!2sLa%20Table%20du%209%20Bis!5e0!3m2!1sfr!2sfr!4v1676898764765!5m2!1sfr!2sfr"
                        width="100%"
                        height="100%"
                        style="border: 0; min-height: 200px"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from "../plugins/router";
import { functions } from "@/plugins/firebase";
import { httpsCallable } from "firebase/functions";
const latabledu9bis_SaveContact = httpsCallable(functions, "latabledu9bis_SaveContact");

const empty_form = {
    name: "",
    email: "",
    phone: "",
    object: "",
    message: "",
};

export default {
    created() {
        this.cleanData();
    },
    data() {
        return {
            isLoading: false,
            hasError: false,
            form_data: {
                name: "DMP EVENTS",
                email: "damien@mod1.fr",
                phone: "0789215568",
                object: "Demande de contact",
                message:
                    "Esse mollit sunt ex dolore occaecat ea duis laboris enim. Anim enim adipisicing occaecat culpa fugiat ea aliquip. Quis aliquip eu ad minim et deserunt. Duis quis elit cupidatat anim. Reprehenderit Lorem adipisicing id nostrud eu consectetur id laboris. Quis non in sit sint voluptate. Aute aliquip do minim sit eu.",
            },
        };
    },
    methods: {
        async sendContact() {
            if (!this.isLoading) {
                this.isLoading = true;
                const form = document.querySelector(".needs-validation");
                form.classList.add("was-validated");

                if (form.checkValidity()) {
                    this.form_data.created_at = new Date().toLocaleString("fr-FR");
                    this.form_data.is_contact_event = false;

                    await latabledu9bis_SaveContact(this.form_data)
                        .then((result) => {
                            const data = result.data;
                            if (data) {
                                this.cleanData();
                                router.push({ name: "done" });
                            } else {
                                this.$data.hasError = true;
                                form.classList.remove("was-validated");
                                window.scrollTo(0, 0);
                            }
                        })
                        .catch((error) => {
                            const code = error.code;
                            const message = error.message;
                            const details = error.details;
                            console.log(code, message, details);
                            this.$data.hasError = true;
                            form.classList.remove("was-validated");
                            window.scrollTo(0, 0);
                        });
                }

                this.isLoading = false;
            }
        },
        cleanData() {
            this.form_data = Object.assign({}, empty_form);
            const form = document.querySelector(".needs-validation");
            form?.classList.remove("was-validated");
        },
    },
};
</script>
